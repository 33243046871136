<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="d-flex justify-content-end">
          <b-button class="mr-2" variant="primary" @click="addsConges()"
            >Ajouter un congé</b-button
          >
        </div>

        <b-card no-body title="" class="mt-1">
          <div class="my-2 mx-1 row">
            <!-- search start -->
            <div class="col-lg-3 col-md-6 mt-2 mt-lg-0">
              <b-form-input
                id="filter-input"
                v-model="valueSearch"
                type="search"
                placeholder="Rechercher"
                @input="filterValue"
              >
              </b-form-input>
            </div>
            <!-- search end -->

            <!-- select personnels start -->
            <div class="col-lg-3 col-md-6 mt-2 mt-lg-0">
              <b-form-select
                v-model="valueMembre"
                :options="options"
                value-field="text"
                text-field="text"
                @input="filterValue"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    style="color: #b4b7bd; text-align: center"
                    >-- Membres --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>
            <div class="col-lg-3 col-md-6 mt-2 mt-lg-0">
              <b-form-select
                v-model="valueAnnee"
                :options="anneesList"
                @input="filterValue"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    style="color: #b4b7bd; text-align: center"
                    >-- Année --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>
            <div class="col-lg-3 col-md-6 mt-2 mt-lg-0">
              <b-input-group prepend="Nombre de jour">
                <b-form-input disabled v-model="nbrJours" />
              </b-input-group>
            </div>
            <!-- select personnels end -->
          </div>

          <!-- table start -->
          <b-table
            id="my-table"
            responsive
            :fields="headers"
            :items="conges"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-function="filterFunction"
            @filtered="onFiltered"
            show-empty
            class="border-bottom"
          >
            <template #cell(personnel)="data">
              <span>
                {{ data.item.personnel ? data.item.personnel.name : "----" }}
              </span>
            </template>

            <template #cell(duration)="data">
              <span>
                {{data.item.duration}} jour(s)
              </span>
            </template>

            <!-- <template #cell(congesNbr)="data">
              <span>
                {{
                  data.item.conges[0].total_days
                    ? data.item.conges[0].total_days
                    : 0
                }}
              </span>
            </template> -->

            <template #cell(actions)="data">
              <b-button
                :id="'button-3' + data.item.id"
                v-if="$hasFonction(user, 'Personnel')"
                size="sm"
                variant="flat-primary"
                class="btn-icon"
                @click="printItem(data.item)"
              >
                <feather-icon icon="FileTextIcon" />
              </b-button>
              <b-tooltip
              placement="left"
                :target="'button-3' + data.item.id"
                title="note de congé"
                variant="dark"
              ></b-tooltip>
            </template>

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	                <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                  </span>
              <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal edit start  -->
        <b-modal
          id="modal-AddConges"
          v-model="modalAddConges"
          title="Ajouter congés"
          @hidden="resetModalAddConges"
          hide-footer
          centered
        >
          <!-- form start -->
          <b-form
            class="mt-1"
            ref="addForm"
            @submit.stop.prevent="handleSubmit"
          >
            <b-form-group label="Membres" label-for="membres">
              <validation-provider
                #default="{ errors }"
                name="membres"
                rules="required"
              >
                <b-form-select
                  id="membres"
                  v-model="addConges.membres"
                  :options="membresList"
                  @input="membresSelectReset"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? errors[0] : membresModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Motif du congé" label-for="motif_conge">
              <validation-provider
                #default="{ errors }"
                name="motif_conge"
                rules="required"
              >
                <b-form-textarea
                  id="motif_conge"
                  v-model="addConges.motif_conge"
                  placeholder="Motif"
                  :state="errors.length > 0 ? false : null"
                  @input="motifInputReset"
                ></b-form-textarea>
                <small class="text-danger">{{
                  errors[0] ? errors[0] : motifModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <div class="row">
              <b-form-group
                class="col"
                label="Date début"
                label-for="date_debut"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date_debut"
                  rules="required"
                >
                
                <b-form-input
              v-model="addConges.date_debut"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="DateInputReset"
              />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : DateDebutModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="col"
                label="Nombre de jours"
                label-for="nombre_jours"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nombre_jours"
                  rules="required"
                >
                  <b-form-input
                    id="nombre_jours"
                    type="number"
                    v-model="addConges.nombre_jours"
                    :state="errors.length > 0 ? false : null"
                    placeholder="0"
                    @input="nombreJoursInputReset"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : nombreJoursModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="modalAddConges = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButton"
                variant="primary"
                class="mt-2"
                @click="handleOk"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal edit end-->
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
        <b-button variant="primary" size="sm" @click="addsConges()">
          <b-icon-plus></b-icon-plus>
          AJOUTER
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  
import { mapState } from "vuex";
import { ModelListSelect } from "vue-search-select";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormDatepicker,
  BIconCircleFill,
  VBTooltip,
  BFormSelectOption,
  BIconArrowClockwise,
  BFormTextarea,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    DatePicker,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    ModelListSelect,
    BFormDatepicker,
    BIconCircleFill,
    BFormTextarea,
    BIconArrowClockwise,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },

  data() {
    return {
      dateFormatOptions: { day: "numeric", month: "long", year: "numeric" },
      etatButton: true,
      options: [],
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      membresList: [],
      selectedMembre: null,
      modalAddConges: false,
      perPage: 10,
      currentPage: 1,
      filter: "",
      allRows: 0,
      pageOptions: [],
      headers: [
        {
          key: "personnel",
          label: "Personnel concerné",
          sortable: true,
        },
        {
          key: "year",
          label: "Année",
          sortable: true,
          class: "text-center",
        },
        {
          key: "from",
          label: "Date de début",
          sortable: true,
        },
        {
          key: "to",
          label: "date de fin",
          sortable: true,
        },
        {
          key: "duration",
          label: "Durée",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: true,
        },
      ],
      conges: [],
      nameState: null,
      addConges: {
        membres: "",
        motif_conge: "",
        date_debut: "",
        nombre_jours: "",
      },
      membresModalErrors: null,
      motifModalErrors: null,
      DateDebutModalErrors: null,
      nombreJoursModalErrors: null,
      valueSearch: null,
      valueMembre: null,
      valueAnnee: null,
      anneesList: [],
    };
  },

  computed: {
    nbrJours() {
      let conges = this.conges;
      let total = 0;

      if (this.valueMembre) {
        conges = conges.filter((cong) => cong.personnel.name == this.valueMembre);
      }

      if (this.valueAnnee) {
        conges = conges.filter((cong) => cong.year == this.valueAnnee);
      }

      conges.forEach((element) => {
        total += element.duration;
      });
      return total;
    },
    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },

    ...mapState({
      user: (state) => state.user.auth,
    }),
  },

  created() {
    this.getConges();
    this.getMembres();
  },

  mounted() {},

  methods: {
    filterValue(val) {
      this.filter = " ";
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },

    filterFunction(row, DateFilter) {
      if (
        (this.valueMembre != null && this.valueMembre != row.personnel.name) ||
        (this.valueAnnee != null && this.valueAnnee != row.year)
      ) {
        return false;
      } else {
        if (
          this.valueSearch &&
          row.personnel.name
            .toLowerCase()
            .indexOf(this.valueSearch.toLowerCase()) == -1
        ) {
          return false;
        }
      }
      return true;
    },
    getMembres() {
      this.$http
        .get("/gestion-personnel/personnels")
        .then((res) => {
          res.data.data.reverse().forEach((element) => {
            var obj = {
              value: element.id,
              text: element.name,
            };

            this.membresList.push(obj);
            this.options.push(obj);
          });
        })
        .catch((e) => {});
    },

    getConges() {
      this.$http
        .get("/gestion-personnel/conges")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }
          this.conges = res.data.data.reverse();
          res.data.data.forEach((element) => {
            if (!this.anneesList.includes(element.year)) {
              this.anneesList.push(element.year);
            }
          });
          if (this.conges.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }

          this.allRows = this.conges.length

        })
        .catch((e) => {});
    },

    // add congés start
    checkFormValidity() {
      const valid = this.$refs.addForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    printItem(item) {
      // console.log(item);
      let toPrint = {};
      let user = item.personnel;

      toPrint.motive = item.motive;
      toPrint.from = item.from;
      toPrint.to = item.to;
      toPrint.duration = item.duration;
      toPrint.year = item.year;
      toPrint.user_name = user.name;
      toPrint.user_poste = user.work;
      toPrint.user_phone = user.phone;
      toPrint.user_salary = user.salary;
      toPrint.hiring_date = user.hiring_date;

      let routeData = this.$router.resolve({
        name: "personnel-print-permission-conge",
        query: toPrint,
      });
      window.open(routeData.href, "_blank");
    },

    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      var dataConges = {
        personnel: this.addConges.membres,
        motive: this.addConges.motif_conge,
        from: this.addConges.date_debut,
        to: this.addConges.nombre_jours,
      };
      this.etatButton = false;
      this.$http
        .post("/gestion-personnel/conges", dataConges)
        .then((result) => {
          if (result.data.success) {
            this.etatButton = true;
            this.addConges = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalAddConges = false;
            let rep = result.data.data;
            let user = result.data.data.personnel;
            let toPrint = {};

            toPrint.motive = rep.motive;
            toPrint.from = rep.from;
            toPrint.to = rep.to;
            toPrint.duration = rep.duration;
            toPrint.year = rep.year;
            toPrint.user_name = user.name;
            toPrint.user_poste = user.work;
            toPrint.user_phone = user.phone;
            toPrint.user_salary = user.salary;
            toPrint.hiring_date = user.hiring_date;
            // toPrint.entete = entete

            let routeData = this.$router.resolve({
              name: "personnel-print-permission-conge",
              query: toPrint,
            });
            window.open(routeData.href, "_blank");

            this.showToast("Congé enregistré avec succès", "success");
            this.getConges();
          } else {
            this.etatButton = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButton = true;
          var err = e.response.data.errors;

          if (err) {
            if (err.personnel) {
              this.membresModalErrors = err.personnel[0];
            }

            if (err.motive) {
              this.motifModalErrors = err.motive[0];
            }

            if (err.from) {
              this.DateDebutModalErrors = err.from[0];
            }

            if (err.to) {
              this.nombreJoursModalErrors = err.to[0];
            }
          } else if (e.response.status == 404) {
            this.addConges = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalAddConges = false;
            this.showToast("Congé enregistré avec succès", "success");
            this.getConges();
          }
        });
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmit();
    },

    resetModalAddConges() {
      this.addConges.membres = "";
      this.addConges.motif_conge = "";
      this.addConges.date_debut = "";
      this.addConges.nombre_jours = "";
      this.nameState = null;
      this.membresSelectReset();
      this.motifInputReset();
      this.DateInputReset();
      this.nombreJoursInputReset();
    },

    membresSelectReset() {
      if (this.membresModalErrors) {
        this.membresModalErrors = null;
      }
    },

    motifInputReset() {
      if (this.motifModalErrors) {
        this.motifModalErrors = null;
      }
    },

    DateInputReset() {
      if (this.DateDebutModalErrors) {
        this.DateDebutModalErrors = null;
      }
    },

    nombreJoursInputReset() {
      if (this.nombreJoursModalErrors) {
        this.nombreJoursModalErrors = null;
      }
    },

    // add congés end

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    addsConges() {
      this.modalAddConges = true;
    },
  },
};
</script>

<style lang="scss">
#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}

// .bgcolor-button button{
// 	background-color: #27295c !important;
// }
</style>
